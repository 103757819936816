import React from "react";
import { Mobile, TabletOrDesktop } from "../util/Responsive";

export default function() {
  return (
    <div style={{ textAlign: "center" }}>
      <Mobile>
        <a
          href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3217214&pid=886577151"
          rel="nofollow"
          target="_blank"
        >
          <img
            src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3217214&pid=886577151"
            border="0"
            style={{ maxWidth: "100%" }}
          />
        </a>
      </Mobile>
      <TabletOrDesktop>
        <a
          href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3217214&pid=886577218"
          rel="nofollow"
          target="_blank"
        >
          <img
            src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3217214&pid=886577218"
            border="0"
            style={{ maxWidth: "100%" }}
          />
        </a>
      </TabletOrDesktop>
    </div>
  );
}

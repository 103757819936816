import React from "react";
import { Helmet } from "react-helmet";
import SeriesDetailList from "../../../component/SeriesDetailList";
import { withRouter } from "react-router";
import axios from "axios";
import queryString from "query-string";
import HuluAdSmall from "../../../ad/HuluAdSmall";

import "./genre.css";

class TvGenre extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      genreId: Number(
        queryString.parse(this.props.location.search).genreId || 1
      ),
      popularTagList: []
    };
  }
  static GENRE_LIST = [
    { id: 1, title: "ドラマ" },
    { id: 2, title: "アクション" },
    { id: 3, title: "ファンタジー" },
    { id: 4, title: "コメディー" },
    { id: 5, title: "ラブロマンス" },
    { id: 6, title: "ミステリー" },
    { id: 7, title: "ホラー" },
    { id: 8, title: "リアリティ番組" },
    { id: 9, title: "ドキュメンタリー" },
    { id: 10, title: "アニメ" }
  ];
  async componentDidMount() {
    const { data } = await axios.get("/json/search/mst/tag.json");
    this.setState({
      ...this.state,
      popularTagList: data
    });
  }
  getGenreTitle(genreId) {
    return TvGenre.GENRE_LIST.find(it => it.id === genreId).title;
  }
  getJson(genreId) {
    return `/json/series/ranking/genre/${genreId}.json`;
  }
  renderSubMenu() {
    return (
      <div className="sub-menu">
        {TvGenre.GENRE_LIST.map(genre => (
          <a href={`/tv/genre.html?genreId=${genre.id}`} key={genre.id}>
            <div
              className={`sub-menu-item ${
                this.state.genreId === genre.id ? "selected" : ""
              }`}
            >
              {genre.title}
            </div>
          </a>
        ))}
      </div>
    );
  }
  renderPopularTags() {
    return (
      <div className="popular-tags-div">
        <div className="popular-tags-header">注目のタグ</div>
        <div className="tag-list medium">
          {this.state.popularTagList.slice(0, 100).map((tag, index) => {
            return (
              <div className={`tag-div ${index < 30 ? "large" : "medium"}`}>
                <div className="tag-link">
                  <a href={`/tv/tag.html?tagId=${tag.i}`}>{tag.d}</a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            海外ドラマ ジャンル別ランキング 「
            {this.getGenreTitle(this.state.genreId)}」編
          </title>
        </Helmet>
        {this.renderSubMenu()}
        <div className="two-column-7525">
          <div className="main-column">
            <div className="content-title">
              <h1 className="ranking-title">
                {this.getGenreTitle(this.state.genreId)}
              </h1>
            </div>
            <SeriesDetailList json={this.getJson(this.state.genreId)} />
          </div>
          <div className="side-column">
            {this.renderPopularTags()}
            <HuluAdSmall />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TvGenre);

import React from "react";

export default function() {
  return (
    <div style={{ textAlign: "center" }}>
      <a
        href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3217214&pid=886577151"
        rel="nofollow"
      >
        <img
          src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3217214&pid=886577151"
          border="0"
          style={{ maxWidth: "100%" }}
        />
      </a>
    </div>
  );
}

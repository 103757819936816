import React from "react";
import { withRouter } from "react-router";
import SeriesDetailList from "../../../component/SeriesDetailList";
import axios from "axios";
import queryString from "query-string";
import HuluAdSmall from "../../../ad/HuluAdSmall";

import "./tag.css";

class TvTag extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      tagId: Number(queryString.parse(this.props.location.search).tagId),
      tag: null,
      seriesList: [],
      popularTagList: []
    };
  }
  async componentDidMount() {
    try {
      const { data } = await axios.get(
        `/json/tag/mst/${this.state.tagId}.json`
      );
      this.setState({
        ...this.state,
        tag: data
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/json/search/mst/tag.json");
      this.setState({
        ...this.state,
        popularTagList: data
      });
    } catch (e) {
      console.log(e);
    }
  }
  renderPopularTags() {
    return (
      <div className="popular-tags-div">
        <div className="popular-tags-header">注目のタグ</div>
        <div className="tag-list medium">
          {this.state.popularTagList.slice(0, 100).map((tag, index) => {
            return (
              <div className={`tag-div ${index < 30 ? "large" : "medium"}`}>
                <div className="tag-link">
                  <a href={`/tv/tag.html?tagId=${tag.i}`}>{tag.d}</a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <div className="two-column-7525">
          <div className="main-column">
            <div className="search-tag">
              <span>「 </span>
              <span className="search-tag-name">
                {this.state.tag ? this.state.tag.d : ""}
              </span>
              <span> 」タグが付いたドラマ一覧</span>
            </div>
            <SeriesDetailList
              json={`/json/series/list/tag/${this.state.tagId}.json`}
            />
          </div>
          <div className="side-column">
            {this.renderPopularTags()}
            <HuluAdSmall />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TvTag);

import React from "react";
import { Helmet } from "react-helmet";
import SeriesDetailList from "../../../component/SeriesDetailList";
import { withRouter } from "react-router";
import axios from "axios";
import queryString from "query-string";
import HuluAdSmall from "../../../ad/HuluAdSmall";

import "./english.css";

class TvEnglish extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      level: Number(queryString.parse(this.props.location.search).lv || 1),
      popularTagList: []
    };
  }
  static LEVEL_LIST = [
    { level: 12, title: "簡単" },
    { level: 345, title: "ふつう" },
    { level: 67, title: "難しい" },
    { level: 89, title: "超難しい" },
    { level: 1, title: "Lv.1 英語初心者" },
    { level: 2, title: "Lv.2 日常会話（入門）" },
    { level: 3, title: "Lv.3 日常会話（中級）" },
    { level: 4, title: "Lv.4 日常会話（実践）" },
    { level: 5, title: "Lv.5 ビジネス会話（入門）" },
    { level: 6, title: "Lv.6 ビジネス会話（中級）" },
    { level: 7, title: "Lv.7 ビジネス会話（実践）" },
    { level: 8, title: "Lv.8 準ネイティブ" },
    { level: 9, title: "Lv.9 ネイティブ" }
  ];
  getLevelTitle(level) {
    return TvEnglish.LEVEL_LIST.find(it => it.level === level).title;
  }
  getJson(level) {
    return `/json/series/ranking/english/level/${level}.json`;
  }
  renderSubMenu() {
    return (
      <div className="sub-menu">
        {[12, 345, 67, 89].map(level => (
          <a href={`/tv/english.html?lv=${level}`} key={level}>
            <div
              className={`sub-menu-item ${
                this.state.level === level ? "selected" : ""
              }`}
            >
              {this.getLevelTitle(level)}
            </div>
          </a>
        ))}
      </div>
    );
  }
  renderLevelList(groupName, levels) {
    return (
      <div>
        <div className="ranking-header">{groupName}</div>
        {levels.map(level => (
          <div>
            <a href={`/tv/english.html?lv=${level}`}>
              {this.getLevelTitle(level)}
            </a>
          </div>
        ))}
      </div>
    );
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            海外ドラマ 英語レベル別ランキング「
            {this.getLevelTitle(this.state.level)}」編
          </title>
        </Helmet>
        {this.renderSubMenu()}
        <div className="two-column-7525">
          <div className="main-column">
            <div className="content-title">
              <h1 className="ranking-title">
                {this.getLevelTitle(this.state.level)}
              </h1>
            </div>
            <SeriesDetailList json={this.getJson(this.state.level)} />
          </div>
          <div className="side-column">
            <div className="other-ranking">
              {this.renderLevelList("＜英語レベル一覧＞", [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9
              ])}
            </div>
            <HuluAdSmall />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TvEnglish);

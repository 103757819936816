import React, { useState } from "react";
import nl2br from "../../../util/nl2br";

import "./review-box.css";

export default function({ reviewList }) {
  const [numberOfDisplayed, setNumberOfDisplayed] = useState(3);

  return (
    <div className="tab-switch">
      <div className="tab-name-div">
        <h2 className="tab-name selected">ドラマの口コミ</h2>
      </div>
      <div className="tab-element-div">
        <div className="tab-element">
          <div className="user-review">
            {reviewList.length > 0 ? (
              <div className="load-control">
                <div className="load-content-div">
                  {reviewList.slice(0, numberOfDisplayed).map(review => (
                    <ReviewItem review={review} />
                  ))}
                </div>
                {reviewList.length > numberOfDisplayed && (
                  <div className="load-button-div">
                    <div
                      className="load-button"
                      onClick={() =>
                        setNumberOfDisplayed(numberOfDisplayed + 3)
                      }
                    >
                      もっと見る
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="no-review">口コミがありません。</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ReviewItem({ review }) {
  const [showSpoiler, setShowSpoiler] = useState(false);

  return (
    <div key={review.i} className="review-box-div">
      <div className="review-box">
        <div className="review-user">
          <div
            className={`review-sex-age ${review.x === 1 ? "male" : "female"}`}
          >
            <span className="review-age">{review.a}</span>歳
          </div>
          <div className="user-nickname">
            <span>{review.n}</span>
          </div>
        </div>
        <div className="review-text-box">
          <div className="rating-star-div">
            <img
              src={`/img/ui/rating-star/${Math.round(review.r * 2) / 2}.png`}
            />
          </div>
          <div className="review-title">{review.h}</div>
          {review.f && !showSpoiler ? (
            <div class="spoiler-link" onClick={() => setShowSpoiler(true)}>
              ネタバレを含む口コミを表示する...
            </div>
          ) : (
            <div className="review-text">
              <div className="text-with-br">
                <p>
                  <span>{review.t}</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

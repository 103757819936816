import React from "react";

export default function(text) {
  if (!text) return text;

  const regex = /(\n)/g;
  return text.split(regex).map(line => {
    if (line.match(regex)) {
      return React.createElement("br");
    } else {
      return line;
    }
  });
}

import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import "./header.css";

class Header extends React.Component {
  render() {
    const path = this.props.location.pathname;
    return (
      <div id="header-wrap">
        <div id="header">
          <div id="user-bar-wrap">
            <div id="user-bar">
              <div className="site-description">
                あなたにピッタリのドラマが見つかる！英語学習には海外ドラマ！
              </div>
            </div>
          </div>
          <div id="logo-bar">
            <div className="logo-div">
              <a href="/">
                <img
                  src="/img/template/logo.png"
                  className="logo-img"
                  alt="ロゴ"
                />
              </a>
            </div>
            <div className="tagline-div">
              <a href="/">
                <strong className="tagline-highlight">海外ドラマ</strong>
                図鑑
              </a>
            </div>
          </div>
          <div id="menu-bar">
            <div className="menu-item-div">
              <div
                className={`menu-item ${
                  path === "/" || path === "/tv/index.html" ? "selected" : ""
                }`}
              >
                <a href="/tv/index.html">
                  <span className="sub-menu-title">トップ</span>
                </a>
              </div>
              <div
                className={`menu-item ${
                  path === "/tv/ranking.html" ? "selected" : ""
                }`}
              >
                <a href="/tv/ranking.html?rankingId=all">
                  <span className="sub-menu-title">ランキング</span>
                </a>
              </div>
              <div
                className={`menu-item ${
                  path === "/tv/genre.html" ? "selected" : ""
                }`}
              >
                <a href="/tv/genre.html?genreId=1">
                  <span className="sub-menu-title">ジャンル別</span>
                </a>
              </div>
              <div
                className={`menu-item ${
                  path === "/tv/english.html" ? "selected" : ""
                }`}
              >
                <a href="/tv/english.html?lv=12">
                  <span className="sub-menu-title">英語レベル別</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);

import React, { useState } from "react";
import nl2br from "../../../util/nl2br";

import "./simple-series-list.css";

export default function({ tagList, similarSeriesList }) {
  const [selectedTab, setSelectedTab] = useState("タグ情報");

  return (
    <div className="tab-switch">
      <div className="tab-name-div">
        <h2
          className={`tab-name ${selectedTab === "タグ情報" ? "selected" : ""}`}
          onClick={() => setSelectedTab("タグ情報")}
        >
          タグ情報
        </h2>
        <h2
          className={`tab-name ${
            selectedTab === "類似ドラマ" ? "selected" : ""
          }`}
          onClick={() => setSelectedTab("類似ドラマ")}
        >
          類似ドラマ
        </h2>
      </div>
      {selectedTab === "タグ情報" && (
        <div className="tab-element-div">
          <div className="tab-element">
            <div className="series-tag">
              <div className="tag-list large">
                {tagList.slice(0, 50).map((tag, index) => {
                  let size;
                  if (index < 5) {
                    size = "large";
                  } else if (index < 10) {
                    size = "medium";
                  } else {
                    size = "small";
                  }
                  return (
                    <div key={tag.t} className={`tag-div ${size}`}>
                      <div className="tag-link">
                        <a href={`/tv/tag.html?tagId=${tag.t}`}>{tag.d}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedTab === "類似ドラマ" && (
        <div className="tab-element-div">
          <div className="tab-element">
            <div className="similar-series">
              <div className="simple-series-list">
                {similarSeriesList.slice(0, 5).map(series => (
                  <div className="simple-series-item">
                    <a
                      className="series-link"
                      href={`/tv/series.html?id=${series.i}`}
                    >
                      <div className="series-img-div">
                        <img
                          className="series-img"
                          src={`/file/series/thumbnail/${series.i}.jpg`}
                        />
                      </div>
                      <div className="series-content-div">
                        <div className="series-title-jp">{series.j}</div>
                        <div className="series-title-en">{series.e}</div>
                        <div className="series-rating-div">
                          <img
                            src={`/img/ui/rating-star/${Math.round(
                              series.r * 2
                            ) / 2}.png`}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

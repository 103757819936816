import React from "react";

import "./footer.css";

export default function Footer() {
  return (
    <div id="footer-wrap">
      <div id="footer">
        <div className="page-top-button" onClick={() => window.scrollTo(0, 0)}>
          ↑ PAGE TOP ↑
        </div>
        <div id="info-bar-wrap">
          <div id="info-bar">
            <div className="author-msg">
              <div className="author-msg-title">
                「英語ハック」をご利用の皆様
              </div>
              <div className="author-msg-text">
                <div className="text-with-br">
                  <p>
                    <span>
                      英語ハックは、「英語学習には海外ドラマが一番！」という考えのもと、英語学習者の皆様それぞれの好みと英語レベルに合った海外ドラマを見つけることができるサイトを目指しています。海外ドラマは、ネイティブの生の英語を聞くことができ、楽しみながら英語や海外の生活を学べる理想の教材です。自分に合った海外ドラマとの出会いは、きっと英語の上達につながります！
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="rights-bar-wrap">
          <div id="rights-bar">
            <div className="rights-text">
              Copyright © eigohack.com All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Mobile, TabletOrDesktop } from "../util/Responsive";
import SeriesDetailBox from "./SeriesDetailBox";
import HuluAd from "../ad/HuluAd";
import TsutayaAd from "../ad/TsutayaAd";
import UNextAd from "../ad/UNextAd";
import axios from "axios";

import "./series-detail-list.css";

class SeriesDetailList extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      seriesList: [],
      numberOfDisplayedResults: 10
    };
  }
  async componentDidMount() {
    const { data } = await axios.get(this.props.json);
    this.setState({
      ...this.state,
      seriesList: data
    });
  }
  showMore = () => {
    this.setState({
      ...this.state,
      numberOfDisplayedResults: this.state.numberOfDisplayedResults + 10
    });
  };
  render() {
    const seriesDetailList = this.state.seriesList
      .slice(0, this.state.numberOfDisplayedResults)
      .map((it, index) => (
        <div>
          {index === 5 && <HuluAd />}
          {index === 15 && <UNextAd />}
          {index === 25 && <TsutayaAd />}
          <SeriesDetailBox rank={index + 1} series={it} key={index + 1} />
        </div>
      ));
    return (
      <div className="load-control">
        <div className="load-content-div">
          <div className="series-detail-list-div">
            <div className="series-detail-list">{seriesDetailList}</div>
          </div>
        </div>
        {this.state.numberOfDisplayedResults < this.state.seriesList.length && (
          <div className="load-button-div">
            <div
              className="load-button"
              style={{
                fontSize: "110%"
              }}
              onClick={this.showMore}
            >
              もっと見る
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default SeriesDetailList;

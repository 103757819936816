import React, { useState } from "react";
import nl2br from "../../../util/nl2br";

import "./eng-review-box.css";

const LEVEL_LIST = [
  { level: 1, title: "Lv.1 英語初心者" },
  { level: 2, title: "Lv.2 日常会話（入門）" },
  { level: 3, title: "Lv.3 日常会話（中級）" },
  { level: 4, title: "Lv.4 日常会話（実践）" },
  { level: 5, title: "Lv.5 ビジネス会話（入門）" },
  { level: 6, title: "Lv.6 ビジネス会話（中級）" },
  { level: 7, title: "Lv.7 ビジネス会話（実践）" },
  { level: 8, title: "Lv.8 準ネイティブ" },
  { level: 9, title: "Lv.9 ネイティブ" }
];

const ACCENT_TYPE = {
  0: "-",
  1: "アメリカ英語",
  2: "イギリス英語",
  3: "その他の英語"
};

const SUBTITLE_TYPE = {
  0: "-",
  1: "日本語字幕",
  2: "英語字幕",
  3: "字幕なし"
};

function getLevelTitle(level) {
  return LEVEL_LIST.find(it => it.level === level).title;
}

export default function({ english, englishReviewList }) {
  const [numberOfDisplayed, setNumberOfDisplayed] = useState(3);

  return (
    <div className="tab-switch">
      <div className="tab-name-div">
        <h2 className="tab-name selected">英語口コミ</h2>
      </div>
      <div className="tab-element-div">
        <div className="tab-element">
          <div className="english-data">
            <div className="english-data">
              <div className="eng-review-header">
                <div className="eng-info">
                  <div className="eng-info-label">ドラマの英語</div>
                  <div
                    className={`eng-level ${
                      english ? "level" + english.l : ""
                    }`}
                  >
                    推奨レベル：
                    {english && english.l ? getLevelTitle(english.l) : "-"}
                  </div>
                  <div className="eng-accent-type">
                    {english ? ACCENT_TYPE[english.e] : "-"}
                  </div>
                </div>
                <div className="eng-level-help">
                  <div className="level-icon">
                    <img
                      className="level-icon"
                      src="/img/component/eng-review-box/level-icon/1.png"
                    />
                  </div>
                  <div className="level-label">全く問題なかった</div>
                  <div className="level-icon">
                    <img
                      className="level-icon"
                      src="/img/component/eng-review-box/level-icon/2.png"
                    />
                  </div>
                  <div className="level-label">問題なかった</div>
                  <div className="level-icon">
                    <img
                      className="level-icon"
                      src="/img/component/eng-review-box/level-icon/3.png"
                    />
                  </div>
                  <div className="level-label">ふつう</div>
                  <div className="level-icon">
                    <img
                      className="level-icon"
                      src="/img/component/eng-review-box/level-icon/4.png"
                    />
                  </div>
                  <div className="level-label">難しかった</div>
                  <div className="level-icon">
                    <img
                      className="level-icon"
                      src="/img/component/eng-review-box/level-icon/5.png"
                    />
                  </div>
                  <div className="level-label">非常に難しかった</div>
                </div>
              </div>
              {englishReviewList.length > 0 ? (
                <div className="load-control">
                  <div className="load-content-div">
                    <div className="eng-review-box-div">
                      {englishReviewList
                        .slice(0, numberOfDisplayed)
                        .map(review => (
                          <div key={review.i} className="eng-review-box">
                            <div className="header">
                              <div
                                className={`eng-level-title level${review.l}`}
                              >
                                {getLevelTitle(review.l)}
                              </div>
                              <div className="subtitle-type">
                                {review.b ? SUBTITLE_TYPE[review.b] : ""}
                              </div>
                              <div className="eng-level-total">
                                <div className="level-icon">
                                  <img
                                    className="level-icon"
                                    src={`/img/component/eng-review-box/level-icon/${Math.trunc(
                                      review.t
                                    )}.png`}
                                  />
                                </div>
                              </div>
                              <div className="nickname">{review.n}</div>
                            </div>
                            <hr className="divider" />
                            <div className="eng-review-text">
                              <div className="text-with-br">
                                <p>
                                  <span>{review.w}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    {englishReviewList.length > numberOfDisplayed && (
                      <div className="load-button-div">
                        <div
                          className="load-button"
                          onClick={() =>
                            setNumberOfDisplayed(numberOfDisplayed + 3)
                          }
                        >
                          もっと見る
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="no-review">口コミがありません。</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { withRouter } from "react-router";

import "./index.css";
import "./series-box-ranking.css";

class TvIndex extends React.Component {
  renderRankingList(rankingTitle, seriesList) {
    const series = seriesList.map((it, index) => {
      const rank = index + 1;
      return (
        <div
          className={`series-box-div pc ${index < 5 ? "tb" : ""} ${
            index < 3 ? "sp" : ""
          }`}
        >
          {rank <= 5 ? (
            <div className="ranking-img-div">
              <img
                src={`/img/component/series-box-ranking/rank${rank}.gif`}
                className="ranking-img"
                alt={`第${rank}位`}
              />
            </div>
          ) : (
            <div className="ranking-img-div">
              <div className="ranking-no">　</div>
            </div>
          )}
          <div className="series-box">
            <a href={`/tv/series.html?id=${it.seriesId}`}>
              <div className="series-img-div">
                <img
                  className="series-img"
                  src={`/file/series/thumbnail/${it.seriesId}.jpg`}
                  alt={it.seriesTitleJp}
                />
              </div>
            </a>
          </div>
        </div>
      );
    });
    return (
      <div className="series-box-ranking">
        <div className="ranking-title-div">
          <a href="/tv/ranking.html?rankingId=all" className="ranking-url">
            <span className="ranking-title">{rankingTitle}</span>
          </a>
        </div>
        {series}
      </div>
    );
  }
  render() {
    const all = [
      {
        seriesId: 3,
        seriesTitleJp: "フルハウス"
      },
      {
        seriesId: 11,
        seriesTitleJp: "24 -TWENTY FOUR-"
      },
      {
        seriesId: 1,
        seriesTitleJp: "デスパレートな妻たち"
      },
      {
        seriesId: 25,
        seriesTitleJp: "セックス・アンド・ザ・シティ"
      },
      {
        seriesId: 20,
        seriesTitleJp: "BONES－骨は語る－"
      },
      {
        seriesId: 6,
        seriesTitleJp: "アグリー・ベティ"
      },
      {
        seriesId: 148,
        seriesTitleJp: "X－ファイル"
      }
    ];

    const learner = [
      {
        seriesId: 4,
        seriesTitleJp: "フレンズ"
      },
      {
        seriesId: 3,
        seriesTitleJp: "フルハウス"
      },
      {
        seriesId: 25,
        seriesTitleJp: "セックス・アンド・ザ・シティ"
      },
      {
        seriesId: 1,
        seriesTitleJp: "デスパレートな妻たち"
      },
      {
        seriesId: 8,
        seriesTitleJp: "glee/グリー"
      },
      {
        seriesId: 10,
        seriesTitleJp: "ゴシップ・ガール"
      },
      {
        seriesId: 242,
        seriesTitleJp: "セサミストリート"
      }
    ];

    const selfContained = [
      {
        seriesId: 3,
        seriesTitleJp: "フルハウス"
      },
      {
        seriesId: 20,
        seriesTitleJp: "BONES－骨は語る－"
      },
      {
        seriesId: 148,
        seriesTitleJp: "X－ファイル"
      },
      {
        seriesId: 162,
        seriesTitleJp: "コールドケース 迷宮事件簿"
      },
      {
        seriesId: 19,
        seriesTitleJp: "クリミナル・マインド FBI行動分析課（FBI vs. 異常犯罪）"
      },
      {
        seriesId: 86,
        seriesTitleJp: "ミスター・ビーン"
      },
      {
        seriesId: 133,
        seriesTitleJp: "ミディアム～霊能捜査官アリソン・デュボア～"
      }
    ];

    return (
      <div className="main-column">
        {this.renderRankingList("総合ランキング", all)}
        {this.renderRankingList("英語学習者の人気ランキング", learner)}
        {this.renderRankingList("一話完結ドラマランキング", selfContained)}
      </div>
    );
  }
}
export default withRouter(TvIndex);

import React from "react";
import { Helmet } from "react-helmet";
import SeriesDetailList from "../../../component/SeriesDetailList";
import { withRouter } from "react-router";
import queryString from "query-string";
import HuluAdSmall from "../../../ad/HuluAdSmall";

import "./ranking.css";

class TvRanking extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      rankingId:
        queryString.parse(this.props.location.search).rankingId || "all"
    };
  }
  static RANKING_LIST = [
    { id: "all", title: "総合ランキング", json: "all.json" },
    { id: "male", title: "男性ランキング", json: "male.json" },
    { id: "female", title: "女性ランキング", json: "female.json" },
    { id: "learner", title: "学習者ランキング", json: "learner.json" },
    {
      id: "ame",
      title: "アメリカ英語ランキング",
      json: "english/AmE.json"
    },
    {
      id: "bre",
      title: "イギリス英語ランキング",
      json: "english/BrE.json"
    },
    {
      id: "short",
      title: "25分ドラマランキング",
      json: "runtime/short.json"
    },
    {
      id: "medium",
      title: "45分ドラマランキング",
      json: "runtime/medium.json"
    },
    { id: "long", title: "90分ドラマランキング", json: "runtime/long.json" },
    {
      id: "self-contained",
      title: "一話完結ドラマランキング",
      json: "self-contained.json"
    },
    {
      id: "continuous",
      title: "続き物ドラマランキング",
      json: "continuous.json"
    },
    { id: "age10s", title: "10代の人気ランキング", json: "age/10s.json" },
    { id: "age20s", title: "20代の人気ランキング", json: "age/20s.json" },
    { id: "age30s", title: "30代の人気ランキング", json: "age/30s.json" },
    { id: "age40s", title: "40代の人気ランキング", json: "age/40s.json" },
    { id: "age50s", title: "50代の人気ランキング", json: "age/50s.json" },
    { id: "age60s", title: "60代の人気ランキング", json: "age/60s.json" },
    {
      id: "1960s",
      title: "1960年代の人気ランキング",
      json: "start-year/1960s.json"
    },
    {
      id: "1970s",
      title: "1970年代の人気ランキング",
      json: "start-year/1970s.json"
    },
    {
      id: "1980s",
      title: "1980年代の人気ランキング",
      json: "start-year/1980s.json"
    },
    {
      id: "1990s",
      title: "1990年代の人気ランキング",
      json: "start-year/1990s.json"
    },
    {
      id: "2000s",
      title: "2000年代の人気ランキング",
      json: "start-year/2000s.json"
    },
    {
      id: "2010s",
      title: "2010年代の人気ランキング",
      json: "start-year/2010s.json"
    }
  ];
  getRankingTitle(rankingId) {
    return TvRanking.RANKING_LIST.find(it => it.id === rankingId).title;
  }
  getJson(rankingId) {
    return `/json/series/ranking/${
      TvRanking.RANKING_LIST.find(it => it.id === rankingId).json
    }`;
  }
  renderSubMenu() {
    return (
      <div className="sub-menu">
        {["all", "male", "female", "learner"].map(rankingId => (
          <a href={`/tv/ranking.html?rankingId=${rankingId}`}>
            <div
              className={`sub-menu-item ${
                this.state.rankingId === rankingId ? "selected" : ""
              }`}
            >
              {this.getRankingTitle(rankingId)}
            </div>
          </a>
        ))}
      </div>
    );
  }
  renderRankingList(groupName, rankingIds) {
    return (
      <div>
        <div className="ranking-header">{groupName}</div>
        {rankingIds.map(id => (
          <div>
            <a href={`/tv/ranking.html?rankingId=${id}`}>
              {this.getRankingTitle(id)}
            </a>
          </div>
        ))}
      </div>
    );
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            海外ドラマ「{this.getRankingTitle(this.state.rankingId)}」
          </title>
        </Helmet>
        {this.renderSubMenu()}
        <div className="two-column-7525">
          <div className="main-column">
            <div className="content-title">
              <h1 className="ranking-title">
                {this.getRankingTitle(this.state.rankingId)}
              </h1>
            </div>
            <SeriesDetailList json={this.getJson(this.state.rankingId)} />
          </div>
          <div className="side-column">
            <div className="other-ranking">
              {this.renderRankingList("＜英語の種類別＞", ["ame", "bre"])}
              {this.renderRankingList("＜番組構成別＞", [
                "short",
                "medium",
                "long",
                "self-contained",
                "continuous"
              ])}
              {this.renderRankingList("＜年齢別＞", [
                "age10s",
                "age20s",
                "age30s",
                "age40s",
                "age50s",
                "age60s"
              ])}
              {this.renderRankingList("＜年代別＞", [
                "1960s",
                "1970s",
                "1980s",
                "1990s",
                "2000s",
                "2010s"
              ])}
            </div>
            <HuluAdSmall />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TvRanking);

import React from "react";
import { withRouter } from "react-router";
import EnglishReview from "./EnglishReview";
import { Helmet } from "react-helmet";
import IntroductionAndTrailer from "./IntroductionAndTrailer";
import Review from "./Review";
import SeriesDetailList from "../../../component/SeriesDetailList";
import TagAndSimilarSeries from "./TagAndSimilarSeries";
import VideoServices from "./VideoServices";
import axios from "axios";
import nl2br from "../../../util/nl2br";
import queryString from "query-string";
import HuluAd from "../../../ad/HuluAd";
import UNextAd from "../../../ad/UNextAd";
import TsutayaAd from "../../../ad/TsutayaAd";
import AdsenseLandscape from "../../../ad/AdsensePortrait";

import "./series.css";
import "./tab-switch.css";

class TvSeries extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      id: Number(queryString.parse(this.props.location.search).id),
      seriesMst: null,
      genreList: [],
      trailerList: [],
      tagList: [],
      similarSeriesList: [],
      reviewList: [],
      english: null,
      englishReviewList: [],
    };
  }
  static GENRE_LIST = [
    { id: 1, title: "ドラマ" },
    { id: 2, title: "アクション" },
    { id: 3, title: "ファンタジー" },
    { id: 4, title: "コメディー" },
    { id: 5, title: "ラブロマンス" },
    { id: 6, title: "ミステリー" },
    { id: 7, title: "ホラー" },
    { id: 8, title: "リアリティ番組" },
    { id: 9, title: "ドキュメンタリー" },
    { id: 10, title: "アニメ" },
  ];
  async componentDidMount() {
    try {
      const { data } = await axios.get(
        `/json/series/mst/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        seriesMst: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/genre/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        genreList: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/tag/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        tagList: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/review/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        reviewList: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/english/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        english: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/english/user/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        englishReviewList: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/trailer/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        trailerList: data,
      });
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        `/json/series/similarity/${this.state.id}.json`
      );
      this.setState({
        ...this.state,
        similarSeriesList: data,
      });
    } catch (e) {
      console.log(e);
    }
  }
  fileExists(filePath) {
    var http = new XMLHttpRequest();
    http.open("HEAD", filePath, false);
    http.send();
    return http.status !== 404;
  }
  getGenreTitle(genreId) {
    return TvSeries.GENRE_LIST.find((it) => it.id === genreId).title;
  }
  render() {
    if (!this.state.seriesMst) {
      return <div>読み込み中...</div>;
    }
    const {
      seriesId,
      seriesTitleEn,
      seriesTitleJp,
      searchText,
      seasonCount,
      episodeRuntime,
      seriesRating,
      seriesRank,
      startYear,
      endYear,
      seriesTagline,
      seriesIntroduction,
    } = this.state.seriesMst;
    return (
      <div id="contents">
        <Helmet>
          <title>
            {`${seriesTitleJp}（${seriesTitleEn}） あらすじ・口コミ・英語情報`}
          </title>
        </Helmet>
        <div className="series-title-div">
          <div className="series-title">
            <h1 className="series-title-jp">
              <strong>{seriesTitleJp}</strong>
            </h1>
            <div className="series-title-en">
              <strong>{seriesTitleEn}</strong>
            </div>
          </div>
        </div>
        <div className="two-column-28">
          <div className="side-column">
            <div className="series-box">
              <img
                className="series-img"
                src={
                  this.fileExists(`/file/series/thumbnail/${seriesId}.jpg`)
                    ? `/file/series/thumbnail/${seriesId}.jpg`
                    : "/file/series/thumbnail/no-image.jpg"
                }
              />
            </div>
          </div>
          <div className="main-column">
            <div className="basic-data">
              <div className="field-list-div">
                <div className="field-div">
                  <div className="field-name">総合評価</div>
                  <div className="field-content">
                    <div className="field-rating-star">
                      <img
                        src={`/img/ui/rating-star/${
                          Math.round(seriesRating * 2) / 2
                        }.png`}
                      />
                    </div>
                  </div>
                </div>
                <div className="field-div">
                  <div className="field-name">ランキング</div>
                  <div className="field-content">
                    <div className="series-rank-div">{seriesRank} 位</div>
                  </div>
                </div>
                <div className="field-div">
                  <div className="field-name">ジャンル</div>
                  <div className="field-content">
                    <div className="genre-list-div">
                      {this.state.genreList &&
                        this.state.genreList.slice(0, 3).map((it) => (
                          <a
                            key={it.genreId}
                            className="genre-link"
                            href={`/tv/genre.html?genreId=${it.genreId}`}
                          >
                            {this.getGenreTitle(it.genreId)}
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="field-div">
                  <div className="field-name">放送年</div>
                  <div className="field-content">
                    <span>
                      {startYear} 〜 {endYear || ""}
                    </span>
                  </div>
                </div>
                <div className="field-div">
                  <div className="field-name">シーズン数</div>
                  <div className="field-content">
                    <span>{seasonCount}</span>
                  </div>
                </div>
                <div className="field-div">
                  <div className="field-name">１話の長さ</div>
                  <div className="field-content">
                    <span>{episodeRuntime} 分</span>
                  </div>
                </div>
              </div>
            </div>
            <IntroductionAndTrailer
              seriesMst={this.state.seriesMst}
              trailerList={this.state.trailerList}
            />
            {/*<HuluAd />*/}
            <TagAndSimilarSeries
              tagList={this.state.tagList}
              similarSeriesList={this.state.similarSeriesList}
            />
            <div className="tab-switch" style={{ display: "none" }}>
              <div className="tab-name-div">
                <h2 className="tab-name selected">関連ドラマ</h2>
              </div>
              <div className="tab-element-div">
                <div className="tab-element">
                  <div className="related-series" />
                </div>
              </div>
            </div>
            {/*<VideoServices />*/}
            <Review reviewList={this.state.reviewList} />
            {/*<UNextAd />*/}
            <EnglishReview
              english={this.state.english}
              englishReviewList={this.state.englishReviewList}
            />
            <AdsenseLandscape />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TvSeries);

import * as React from "react";
import Responsive from "react-responsive";

export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const MobileOrTablet = props => <Responsive {...props} maxWidth={991} />;
export const Tablet = props => (
  <Responsive {...props} minWidth={768} maxWidth={991} />
);
export const TabletOrDesktop = props => (
  <Responsive {...props} minWidth={768} />
);
export const Desktop = props => <Responsive {...props} minWidth={992} />;
export const PrintHidden = props => <Responsive {...props} query="not print" />;

import React, { useState } from "react";
import nl2br from "../../../util/nl2br";

export default function ({ seriesMst, trailerList }) {
  const [selectedTab, setSelectedTab] = useState(
    seriesMst.seriesIntroduction ? "あらすじ" : "紹介動画"
  );

  return (
    <div className="tab-switch">
      <div className="tab-name-div">
        {seriesMst.seriesIntroduction && (
          <h2
            className={`tab-name ${
              selectedTab === "あらすじ" ? "selected" : ""
            }`}
            onClick={() => setSelectedTab("あらすじ")}
          >
            あらすじ
          </h2>
        )}
        {
          // TODO: 一時的に非表示
          false && trailerList.length > 0 && (
            <h2
              className={`tab-name ${
                selectedTab === "紹介動画" ? "selected" : ""
              }`}
              onClick={() => setSelectedTab("紹介動画")}
            >
              紹介動画
            </h2>
          )
        }
      </div>
      {seriesMst.seriesIntroduction && selectedTab === "あらすじ" && (
        <div className="tab-element-div">
          <div className="tab-element">
            <div className="series-introduction">
              <div className="text-with-br">
                <p>
                  <span>{nl2br(seriesMst.seriesIntroduction)}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {trailerList.length > 0 && selectedTab === "紹介動画" && (
        <div className="tab-element-div">
          <div className="tab-element">
            <div className="series-trailer">
              <div className="youtube-video-wrap">
                {trailerList.map((trailer) => (
                  <div className="youtube-video">
                    <iframe
                      src={`https://www.youtube.com/embed/${trailer.trailerUrlKey}`}
                      frameBorder="0"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

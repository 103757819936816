import "@babel/polyfill";
import React from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import TvEnglish from "../page/tv/English/index";
import TvGenre from "../page/tv/Genre/index";
import TvIndex from "../page/tv/Index/index";
import TvRanking from "../page/tv/Ranking/index";
import TvSeries from "../page/tv/Series/index";
import TvTag from "../page/tv/Tag/index";
import ReactGA from "react-ga";

import "./css/common/util.css";
import "./css/component/series-box.css";
import "./css/component/tag-list.css";
import "./css/template/framework.css";
import "./css/ui/load-control.css";
import "./css/ui/sub-menu.css";

const page404 = () => (
  <div>
    <h1>404</h1>存在しないページです
  </div>
);

class Menu extends React.Component {
  constructor(...args) {
    super(...args);
    ReactGA.initialize("UA-29873991-4");
    this.props.history.listen(({ pathname }) => {
      const url = window.location.pathname + window.location.search;
      console.log(url);
      ReactGA.pageview(url);
    });
  }
  componentDidMount() {
    const url = window.location.pathname + window.location.search;
    console.log(url);
    ReactGA.pageview(url);
  }
  render() {
    return (
      <div style={{ boxSizing: "border-box" }}>
        <Header />

        <div id="contents-wrap">
          <div id="contents">
            <Switch>
              <Route path="/tv/english.html" exact component={TvEnglish} />
              <Route path="/" exact component={TvIndex} />
              <Route path="/tv/genre.html" exact component={TvGenre} />
              <Route path="/tv/index.html" exact component={TvIndex} />
              <Route path="/tv/ranking.html" exact component={TvRanking} />
              <Route path="/tv/series.html" exact component={TvSeries} />
              <Route path="/tv/tag.html" exact component={TvTag} />
              <Route exact component={page404} />
            </Switch>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default withRouter(Menu);

import "@babel/polyfill";
import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@material-ui/core/styles";
import Menu from "./menu";
import theme from "./theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <CookiesProvider>
        <Menu />
      </CookiesProvider>
    </Router>
  </ThemeProvider>,
  document.querySelector("#root")
);

import React from "react";
import axios from "axios";
import nl2br from "../util/nl2br";

import "./series-detail-box.css";

class SeriesDetailBox extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      seriesImage: this.fileExists(
        `/file/series/thumbnail/${this.props.series.seriesId}.jpg`
      )
        ? `/file/series/thumbnail/${this.props.series.seriesId}.jpg`
        : "/file/series/thumbnail/no-image.jpg",
      showMore: false,
      tagList: []
    };
  }
  async componentDidMount() {
    const { data } = await axios.get(
      `/json/series/tag/${this.props.series.seriesId}.json`
    );
    this.setState({
      ...this.state,
      tagList: data
    });
  }
  showMore = () => {
    this.setState({
      ...this.state,
      showMore: true
    });
  };
  fileExists(filePath) {
    var http = new XMLHttpRequest();
    http.open("HEAD", filePath, false);
    http.send();
    return http.status !== 404;
  }
  renderTag(tag, index) {
    const { t: tagId, d: tagName } = tag;
    let size;
    if (index < 5) {
      size = "large";
    } else if (index < 10) {
      size = "medium";
    } else {
      size = "small";
    }
    return (
      <div className={`tag-div ${size}`} key={tagId}>
        <div className="tag-link">
          <a href={`/tv/tag.html?tagId=${tagId}`}>{tagName}</a>
        </div>
      </div>
    );
  }
  render() {
    const {
      seriesId,
      seriesIntroduction,
      seriesRating,
      seriesTitleEn,
      seriesTitleJp
    } = this.props.series;

    const tags = this.state.tagList
      .slice(0, 20)
      .map((tag, index) => this.renderTag(tag, index));

    return (
      <div className="series-detail-box" key={this.props.rank}>
        <div className="list-rank-div">
          <span className="rank-span">
            第 <span className="rank-no-span">{this.props.rank}</span> 位
          </span>
        </div>
        <div className="series-img-div">
          <a href={`/tv/series.html?id=${seriesId}`}>
            <img
              className="series-img"
              src={this.state.seriesImage}
              alt={`${seriesTitleJp}（${seriesTitleEn}）`}
            />
          </a>
        </div>
        <div className="series-info-div">
          <a href={`/tv/series.html?id=${seriesId}`}>
            <div className="series-title-jp">{seriesTitleJp}</div>
            <div className="series-title-en">{seriesTitleEn}</div>
          </a>
          <div className="rating-star-div">
            <img
              src={`/img/ui/rating-star/${Math.round(seriesRating * 2) /
                2}.png`}
            />
          </div>
        </div>
        <div className="series-introduction-div">
          <div className="text-with-br">
            <p>
              <span>
                {seriesIntroduction &&
                  (this.state.showMore
                    ? nl2br(seriesIntroduction)
                    : nl2br(seriesIntroduction.split(/(\n)/g)[0]))}
              </span>
              {seriesIntroduction && !this.state.showMore && (
                <span className="read-more" onClick={this.showMore}>
                  ...続きを読む
                </span>
              )}
              <br />
            </p>
          </div>
        </div>
        <div className="series-tag-div">
          <div className="tag-list small">{tags}</div>
        </div>
      </div>
    );
  }
}
export default SeriesDetailBox;
